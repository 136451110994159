.settings-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 26px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  align-items: center;
  justify-content: space-evenly;
}

.settings-content p {
  width: 50%;
  text-align: center;
}
