.assign-container {
  margin-top: 20px;
  width: 75%;
}
.assign-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 12px 0px;
}
