.rut-checkbox-list {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding-left: 54px;
}

.rut-list {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding-left: 76px;
}

.rut-list-row {
  display: flex;
}
