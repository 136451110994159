.add-row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.add-row-column h6 {
  margin-bottom: 6px;
}

.add-button {
  margin-left: 109px;
}

.comment-component {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 12px 0px;
}

.subcomponent-title {
  display: flex;
  flex-direction: row;
  margin: 0px 24px;
}
