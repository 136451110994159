.success-text-container-top {
  display: flex;
  align-items: center;
  margin: 26px 35px 9px;
}

.success-text-container-bottom {
  display: flex;
  align-items: center;
  margin: 9px 35px;
}
