#userMenu li {
  color: #6cb86a;
}

#userMenu li:hover {
  background: #6cb86a26 0% 0% no-repeat padding-box;
}

.logout {
  color: #ae72a3 !important;
}

.logout:hover {
  background: #ae72a326 0% 0% no-repeat padding-box !important;
}

.divider {
  margin: 0px 10px !important;
}
