.card {
  cursor: pointer;
  height: 106px;
  width: 106px;
}

.card:hover {
  transition: box-shadow 0.3s;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)
  /* box-shadow: 0 14px 35px -10.125px rgba(0, 0, 0, 0.3);
  background-color: #447355; */
}

/* .card:hover .text-card {
  color: white;
}

.card:hover svg {
  color: white !important;
} */

.content {
  height: 82%;
  margin: 9px 12px;
}

.text-card {
  display: flex;
  height: 50%;
  justify-content: center;
  align-items: flex-end;
  pointer-events: none;
}

.icon {
  display: flex;
  height: 50%;
  justify-content: center;
  align-items: flex-start;
  pointer-events: none;
}

.icon svg {
  font-size: 45px;
}
