.row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.column-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
}
