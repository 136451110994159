.switches_rows {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.switches_rows p {
  height: 38px;
  min-width: 330px;
  padding: 8px 0px;
}

.switch_blue .MuiSwitch-thumb {
  background-color: #6a768c;
}

.switch_green .MuiSwitch-thumb {
  background-color: #447355;
}

.title-loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
