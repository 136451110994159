.modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 26px;
  margin-bottom: 15px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0px 45px;
  align-items: center;
  justify-content: space-evenly;
}

.MuiGrid-container {
  margin-top: 0px;
}

.x-button {
  display: flex;
  justify-content: right;
  margin-top: 10px;
  margin-right: 8px;
}

.error-robot-info {
  display: flex;
  flex-direction: column;
  height: 60px;
  margin-top: 20px;
  justify-content: space-around;
}
