.print-content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 20px;
}

.print-detail {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pdf #document {
  margin-top: 22px;
}
