.title-wrapper {
  display: flex;
  flex-direction: row;
  text-align: left;
  padding: 20px;
  margin-bottom: 7px;
}
.addon-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 29px;
}
