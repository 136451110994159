.detail-padding {
  padding: 12px 38px;
}

.top-detail .detail-padding {
  padding-top: 0px;
}

.detail-item {
  margin-right: 56px;
  margin-bottom: 18px;
}

.bottom-wrapper {
  background-color: #f5f5f5;
  height: 62%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.bottom-wrapper .detail-item {
  margin-top: 15px;
}

.bottom-wrapper .buttons-wrapper {
  margin-bottom: 28px;
}

.buttons-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

.notification-battery {
  display: flex;
  align-items: center;
  width: 100%;
  height: 96px;
  padding: 35px 38px;
  background: #ebc172 0% 0% no-repeat padding-box;
  border: 1px solid #ebc172;
  border-radius: 4px;
  margin-bottom: 15px;
}

.notification-error {
  display: flex;
  align-items: center;
  width: 100%;
  height: 96px;
  padding: 35px 38px;
  background: #ede0eb 0% 0% no-repeat padding-box;
  border: 1px solid #ede0eb;
  border-radius: 4px;
  margin-bottom: 24px;
}

.notification-item-icons {
  display: flex;
  align-items: flex-start;
  margin-right: 26px;
}

.notification-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 168px;
  margin-right: 56px;
}
