.grid-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 64%;
  padding-bottom: 15px;
  background-color: #f5f5f5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  align-items: center;
  justify-content: space-evenly;
}

.MuiGrid-container {
  margin-top: 0px;
}

.x-button {
  display: flex;
  justify-content: right;
  margin-top: 10px;
  margin-right: 8px;
}
