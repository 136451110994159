.title-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 9px 0px;
}

.comment-section {
  display: flex;
  justify-content: space-between;
  margin: 18px 0px;
}

.serial_div {
  display: flex;
  flex-direction: row;
  min-width: 320px;
  justify-content: space-between;
  align-items: center;
}
