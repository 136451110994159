.test-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 9px 14px;
}

.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 29px;
}
